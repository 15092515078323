.w-500[data-v-3ed73346] {
  width: 500px;
}
.inline-div[data-v-3ed73346] {
  width: 680px;
}
.inline-div .el-form-item[data-v-3ed73346] {
    width: 50%;
    display: inline-block;
}
.inline-div .el-input__inner[data-v-3ed73346] {
    width: 160px;
}
.amap-maptypecontrol .amap-maptype-list[data-v-3ed73346] {
  display: none;
}
.amap-page-container[data-v-3ed73346] {
  position: relative;
  margin-top: 110px;
}
.amap-page-container .amap-box[data-v-3ed73346] {
    width: 100%;
    height: 500px;
}
.amap-page-container .search[data-v-3ed73346] {
    background-color: #e5e5e5;
    position: absolute;
    top: -120px;
    left: 0px;
    width: 100%;
    height: 120px;
    padding: 20px;
}
.amap-page-container .search .search-box[data-v-3ed73346] {
      position: relative;
}
.amap-page-container .search .search-text[data-v-3ed73346] {
      margin-top: 10px;
}
.number-append[data-v-3ed73346] {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  background-color: #f0f2f5;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #DCDFE6;
  border-left: none;
  width: 54px;
  text-align: center;
  color: #909399;
}
