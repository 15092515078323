.edit-form .el-input__inner {
  border-color: #f0f2f5 !important;
}
.edit-form .block-item {
  width: 680px;
}
.edit-form .block-item .el-form-item__content {
    width: 530px;
}
.edit-form .block-item .el-select {
    width: 100%;
}
.number-input .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.number-input .el-input-number__decrease {
  border-left: 0 !important;
  border-radius: 0;
}
.number-input .el-input-number__decrease, .number-input .el-input-number__increase {
  background-color: transparent;
}
.number-input .el-input-number__increase {
  border-left: 0;
  border-radius: 0;
}
.number-input .el-input-number__increase {
  border-bottom: 0 !important;
}
